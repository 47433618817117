import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const getZoomAuthUrl = async () => {
  const baseURL = config.API_URL;
  const path = "/zoom/auth_url";

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(
    baseURL + path,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
  console.log(res);
  return res;
};


export const zoomAuthCallback = async (therapist_id, code) => {
  const baseURL = config.API_URL;
  const path = "/zoom/oauth_callback";

  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.post(
    baseURL + path,
    {
      therapist_id,
      code,
    },
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
  return res;
};

export const checkZoomTokenStatus = async (therapist_id) => {
  const baseURL = config.API_URL;
  const path = "/zoom/check_token_status";
  const params = "?therapist_id=" + therapist_id;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(
    baseURL + path + params,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );

  return res;
};
