import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkZoomTokenStatus, getZoomAuthUrl } from "../../api/zoom";
import { Video } from "lucide-react";
import { Button } from "../../components/button";


function ZoomSettingsPage() {
  const [zoomStatus, setZoomStatus] = useState("Checking...");
  const therapist_id = useSelector((store) => store.therapist.therapist_id);

  useEffect(() => {
    const fetchZoomTokenStatus = async () => {
      try {
        const res = await checkZoomTokenStatus(therapist_id);

        const { data } = res; 
        const { hasToken, refreshTokenExpired } = data;
        console.log("hasToken", hasToken);
        console.log("refreshTokenExpired", refreshTokenExpired);
        if (!hasToken) {
          setZoomStatus("Not Connected");
        } else if (hasToken && !refreshTokenExpired) {
          setZoomStatus("Connected");
        } else if (hasToken && refreshTokenExpired) {
          setZoomStatus("Token Expired");
        }
      } catch (error) {
        console.error("Error checking Zoom token status:", error);
        setZoomStatus("Not Connected");
      }
    }

    fetchZoomTokenStatus();
  }, []);

  // Handler from snippet #1 (renamed for clarity):
  const handleConnectZoom = async () => {
    try {
      const res = await getZoomAuthUrl();
      const data = res.data;
      if (data.authUrl) {
        window.location.href = data.authUrl;
      } else {
        alert("No authUrl returned from server.");
      }
    } catch (error) {
      console.error(error);
      alert("Failed to initiate Zoom OAuth.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6 w-full">
      {/* Outer Container from snippet #2 */}
      <div className="max-w-3xl mx-auto space-y-6">

        {/* Header Section from snippet #2 */}
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Integrations</h1>
          <p className="text-gray-600 mt-1">Manage your external app connections</p>
        </div>

        {/* Main Content from snippet #3 (Zoom Integration Card) */}
        <div className="bg-white shadow rounded-lg">
          {/* Card Header */}
          <div className="px-6 py-4 border-b">
            <div className="flex items-center">
              <Video className="h-6 w-6 text-gray-400 mr-3" />
              <h3 className="text-lg font-medium text-gray-900">
                Zoom Integration
              </h3>
            </div>
          </div>

          {/* Card Body */}
          <div className="px-6 py-4 bg-gray-50">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm font-medium text-gray-500">Status</p>
                <p className="text-sm text-gray-900">{zoomStatus}</p>
              </div>
              <Button
                className="flex items-center justify-center gap-2"
                onClick={handleConnectZoom}
              >
                <Video className="h-4 w-4" />
                {zoomStatus === "Connected" ? "Re-connect Account" : "Connect Account"}
              </Button>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default ZoomSettingsPage;
