import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { zoomAuthCallback } from "../../api/zoom";
import { Loader2 } from "lucide-react";

function ZoomCallback() {
  // "loading" | "success" | "error"
  const [status, setStatus] = useState("loading");
  const [message, setMessage] = useState("We are connecting your Zoom account. Please wait...");

  const therapist_id = useSelector((store) => store.therapist.therapist_id);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");

    if (!code) {
      setStatus("error");
      setMessage("No authorization code found. Please re-initiate Zoom connection.");
      return;
    }

    const fetchTokens = async () => {
      try {
        const res = await zoomAuthCallback(therapist_id, code);

        if (res.status >= 200 && res.status < 300) {
          setStatus("success");
          setMessage("Your Zoom account is now connected! Redirecting...");
        } else {
          setStatus("error");
          setMessage("We encountered an issue connecting your Zoom account. Please try again.");
        }
      } catch (error) {
        console.error(error);
        setStatus("error");
        setMessage(`Connection failed: ${error.message}`);
      } finally {
        // Wait a moment to let user see final message, then redirect
        setTimeout(() => {
          window.location.href = "/zoom/connect";
        }, 2000);
      }
    };

    fetchTokens();
  }, [therapist_id]);

  // Decide title based on status
  const getTitle = () => {
    switch (status) {
      case "loading":
        return "Zoom Account Connection";
      case "success":
        return "Zoom Connection Successful";
      case "error":
        return "Zoom Connection Failed";
      default:
        return "Zoom Account Connection";
    }
  };

  return (
    <div className="my-10 mx-8 text-center flex flex-col items-center">
      <h1 className="text-3xl font-bold mb-6">{getTitle()}</h1>

      {status === "loading" ? (
        <div className="flex items-center gap-4">
          <Loader2 className="h-10 w-10 animate-spin" />
          <p className="text-xl">{message}</p>
        </div>
      ) : (
        <p className="text-xl">{message}</p>
      )}
    </div>
  );
}

export default ZoomCallback;
